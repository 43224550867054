var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bank-account-container"},[_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"label"},[_c('label',[_vm._v(_vm._s(_vm.$t("BANK_ACCOUNT_COMPONENT_LABEL_BANK_ACCOUNT")))])]),_c('div',{staticClass:"wrap-bank-account"},[_c('ValidationObserver',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('ValidationProvider',{attrs:{"name":"BANK_ACCOUNT_COMPONENT_FIELD_BANK_NAME","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_BANK_NAME'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.bank_name),callback:function ($$v) {_vm.$set(_vm.bankAccount, "bank_name", $$v)},expression:"bankAccount.bank_name"}}),_c('CRow',{staticClass:"group-err-msg"},[_c('CCol',{attrs:{"sm":{offset:'4'}}}),_c('CCol',{attrs:{"md":8}},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"BANK_ACCOUNT_COMPONENT_FIELD_BRANCH_NAME","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_BRANCH_NAME'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.branch_name),callback:function ($$v) {_vm.$set(_vm.bankAccount, "branch_name", $$v)},expression:"bankAccount.branch_name"}}),_c('CRow',{staticClass:"group-err-msg"},[_c('CCol',{attrs:{"sm":{offset:'4'}}}),_c('CCol',{attrs:{"md":8}},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"BANK_ACCOUNT_COMPONENT_FIELD_ACCOUNT_TYPE","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_TYPE'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.account_type),callback:function ($$v) {_vm.$set(_vm.bankAccount, "account_type", $$v)},expression:"bankAccount.account_type"}}),_c('CRow',{staticClass:"group-err-msg"},[_c('CCol',{attrs:{"sm":{offset:'4'}}}),_c('CCol',{attrs:{"md":8}},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"BANK_ACCOUNT_COMPONENT_FIELD_ACCOUNT_NUMBER","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_NUMBER'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.account_number),callback:function ($$v) {_vm.$set(_vm.bankAccount, "account_number", $$v)},expression:"bankAccount.account_number"}}),_c('CRow',{staticClass:"group-err-msg"},[_c('CCol',{attrs:{"sm":{offset:'4'}}}),_c('CCol',{attrs:{"md":8}},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"BANK_ACCOUNT_COMPONENT_FIELD_BENEFICIARY_NAME","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_BENEFICIARY_NAME'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.account_name),callback:function ($$v) {_vm.$set(_vm.bankAccount, "account_name", $$v)},expression:"bankAccount.account_name"}}),_c('CRow',{staticClass:"group-err-msg"},[_c('CCol',{attrs:{"sm":{offset:'4'}}}),_c('CCol',{attrs:{"md":8}},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"BANK_ACCOUNT_COMPONENT_FIELD_CURRENCY","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_CURRENCY'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.currency_symbol),callback:function ($$v) {_vm.$set(_vm.bankAccount, "currency_symbol", $$v)},expression:"bankAccount.currency_symbol"}}),_c('CRow',{staticClass:"group-err-msg"},[_c('CCol',{attrs:{"sm":{offset:'4'}}}),_c('CCol',{attrs:{"md":8}},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('CTextarea',{attrs:{"label":_vm.$t('BANK_ACCOUNT_COMPONENT_LABEL_MEMO'),"rows":"5","horizontal":{ label: 'col-sm-4', input: 'col-sm-8'}},model:{value:(_vm.bankAccount.memo),callback:function ($$v) {_vm.$set(_vm.bankAccount, "memo", $$v)},expression:"bankAccount.memo"}}),_c('CRow',{staticClass:"form-actions"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdatingBankAccount)?_c('CButton',{staticClass:"btn btn-bank-account",attrs:{"color":"primary"},on:{"click":function($event){return _vm.save(_vm.bankAccount)}}},[_vm._v(_vm._s(_vm.$t("BANK_ACCOUNT_COMPONENT_BUTTON_SAVE")))]):_vm._e()],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }