var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CDataTable',{attrs:{"id":"list-history-bank-account","items":_vm.isAllowedView ? _vm.historyBankAccount : [],"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","pagination":"","itemsPerPage":_vm.limit},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")((item.createdAt),_vm.dateFormat)))]):_c('td',[_vm._v("-")])]}},{key:"bank_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"hidden-text"},[_vm._v(_vm._s(item.bank_name || '-'))])]}},{key:"branch_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"hidden-text"},[_vm._v(_vm._s(item.branch_name || '-'))])]}},{key:"account_type",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.account_type || '-'))])]}},{key:"account_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.account_name || '-'))])]}},{key:"account_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"hidden-text"},[_vm._v(_vm._s(item.account_number || '-'))])]}},{key:"currency_symbol",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.currency_symbol || '-'))])]}},{key:"memo",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.memo || '-'))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }