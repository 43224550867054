<template>
  <div class="exchange-rate">
    <CCard>
      <CCardBody>
        <CRow class>
          <CCol col="12" md="6">
            <BankAccountComponent @save="updateBankAccount"/>
          </CCol>
        </CRow>

        <div class="history" v-if="isAllowedView">
          <h6>{{$t("BANK_ACCOUNT_PAGE_TITLE_HISTORY")}}</h6>
          <CRow>
            <CCol md="12">
              <ListHistoryBankAccount
                :isAllowedView="isAllowedView"
                :limit="limit"
                :offset="offset"
                :activePage="activePage"
                :historyBankAccount="historyBankAccount"
                :total="total"
              />
            </CCol>
            <CCol md="12">
              <StakingPagination
                :limit="limit"
                :total="total"
                :offset="offset"
                :activePage="activePage"
                @changePage="onChangePage"
                @changePageSize="onChangePageSize"
              />
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import BankAccountComponent from '@/components/BankAccount/BankAccountComponent';
import StakingPagination from '@/components/StakingPagination';
import ListHistoryBankAccount from '@/components/BankAccount/ListHistoryBankAccountComponent';
import { endpoints, env } from '@/constants';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'BankAccount',
  components: {
    BankAccountComponent,
    ListHistoryBankAccount,
    StakingPagination
  },
  data() {
    return {
      isAllowedView: true,
      total: 0,
      limit: env.app.pageSize,
      historyBankAccount: [],
      activePage: 1
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.getHistoryBankAccount();
  },
  methods: {
    async onChangePage(page) {
      this.activePage = page;
      this.getHistoryBankAccount();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;
      this.getHistoryBankAccount();
    },
    updateBankAccount() {
      this.getHistoryBankAccount();
    },
    async getHistoryBankAccount() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getHistoryBankAccount, { params });
        if (result) {
          this.historyBankAccount = result.data.items;
          this.total = result.data.total;
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_RATE_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  }
};
</script>

<style>
</style>
