<template>
  <div>
    <CCard>
      <CDataTable
        id="list-history-bank-account"
        :items="isAllowedView ? historyBankAccount : []"
        :fields="fields"
        fixed
        hover
        striped
        bordered
        pagination
        :itemsPerPage="limit"
      >
        <!-- <td slot="#" slot-scope="{ index }">{{index + 1 + offset}}</td> -->
        <!-- <td slot="no-items-view">No Items</td> -->
        <template ##="{item, index}">
          <td>{{index + 1 + (activePage-1)*limit}}</td>
        </template>

        <template #createdAt="{item}">
          <td v-if="item.createdAt" class="text-nowrap">{{(item.createdAt) | moment(dateFormat)}}</td>
          <td v-else>-</td>
        </template>
        <template #bank_name="{item}">
          <td class="hidden-text">{{item.bank_name || '-'}}</td>
        </template>
        <template #branch_name="{item}">
          <td class="hidden-text">{{item.branch_name || '-'}}</td>
        </template>
        <template #account_type="{item}">
          <td>{{item.account_type || '-'}}</td>
        </template>
        <template #account_name="{item}">
          <td class="text-nowrap">{{item.account_name || '-'}}</td>
        </template>
        <template #account_number="{item}">
          <td class="hidden-text">{{item.account_number || '-'}}</td>
        </template>
        <template #currency_symbol="{item}">
          <td>{{item.currency_symbol || '-'}}</td>
        </template>
        <template #memo="{item}">
          <td class="text-nowrap">{{item.memo || '-'}}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { Constants } from '@/constants';
export default {
  name: 'ListHistoryBankAccount',
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'createdAt',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_CREATED_AT'),
        },
        {
          key: 'bank_name',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_BANK_NAME'),
        },
        {
          key: 'branch_name',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_BRANCH_NAME'),
        },
        {
          key: 'account_type',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_TYPE'),
        },
        {
          key: 'account_name',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_NAME'),
        },
        {
          key: 'account_number',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_NUMBER'),
        },
        {
          key: 'currency_symbol',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_CURRENCY_SYMBOL'),
        },
        {
          key: 'memo',
          label: this.$t('LIST_HISTORY_BANK_ACCOUNT_COMPONENT_LABEL_MEMO'),
        },
      ],
    };
  },
  props: {
    historyBankAccount: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
    offset: {
      type: Number,
      default: 0
    },
    isAllowedView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
  #list-history-bank-account {
    table {
      thead tr th {
        white-space: nowrap;
      }
      tbody tr {
        .hidden-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 120px;
          @media (max-width: 768px) {
            max-width: 100%;
          }
        }
      }
    }
  }
</style>