<template>
  <div class="bank-account-container">
    <CCard>
      <CCardBody>
        <div class="label">
          <label>{{$t("BANK_ACCOUNT_COMPONENT_LABEL_BANK_ACCOUNT")}}</label>
        </div>
        <div class="wrap-bank-account">
          <ValidationObserver ref="observer" @submit.prevent="save">
            <ValidationProvider
              v-slot="{ errors }"
              name="BANK_ACCOUNT_COMPONENT_FIELD_BANK_NAME"
              rules="required|max:100"
            >
              <CInput
                :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_BANK_NAME')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                v-model="bankAccount.bank_name"
              />
              <CRow class="group-err-msg">
                <CCol :sm="{offset:'4'}"></CCol>
                <CCol :md="8">
                  <span class="error-msg mt-1">{{errors[0]}}</span>
                </CCol>
              </CRow>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="BANK_ACCOUNT_COMPONENT_FIELD_BRANCH_NAME" rules="required|max:100">
              <CInput
                :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_BRANCH_NAME')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                v-model="bankAccount.branch_name"
              />
              <CRow class="group-err-msg">
                <CCol :sm="{offset:'4'}"></CCol>
                <CCol :md="8">
                  <span class="error-msg mt-1">{{errors[0]}}</span>
                </CCol>
              </CRow>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="BANK_ACCOUNT_COMPONENT_FIELD_ACCOUNT_TYPE" rules="required|max:100">
              <CInput
                :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_TYPE')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                v-model="bankAccount.account_type"
              />
              <CRow class="group-err-msg">
                <CCol :sm="{offset:'4'}"></CCol>
                <CCol :md="8">
                  <span class="error-msg mt-1">{{errors[0]}}</span>
                </CCol>
              </CRow>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="BANK_ACCOUNT_COMPONENT_FIELD_ACCOUNT_NUMBER"
              rules="required|max:100"
            >
              <CInput
                :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_ACCOUNT_NUMBER')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                v-model="bankAccount.account_number"
              />
              <CRow class="group-err-msg">
                <CCol :sm="{offset:'4'}"></CCol>
                <CCol :md="8">
                  <span class="error-msg mt-1">{{errors[0]}}</span>
                </CCol>
              </CRow>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="BANK_ACCOUNT_COMPONENT_FIELD_BENEFICIARY_NAME" rules="required|max:100">
              <CInput
                :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_BENEFICIARY_NAME')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                v-model="bankAccount.account_name"
              />
              <CRow class="group-err-msg">
                <CCol :sm="{offset:'4'}"></CCol>
                <CCol :md="8">
                  <span class="error-msg mt-1">{{errors[0]}}</span>
                </CCol>
              </CRow>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="BANK_ACCOUNT_COMPONENT_FIELD_CURRENCY" rules="required|max:100">
              <CInput
                :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_CURRENCY')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                v-model="bankAccount.currency_symbol"
              />
              <CRow class="group-err-msg">
                <CCol :sm="{offset:'4'}"></CCol>
                <CCol :md="8">
                  <span class="error-msg mt-1">{{errors[0]}}</span>
                </CCol>
              </CRow>
            </ValidationProvider>
            <CTextarea
              :label="$t('BANK_ACCOUNT_COMPONENT_LABEL_MEMO')" 
              rows="5"
              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
              v-model="bankAccount.memo"
            />
            <CRow class="form-actions">
              <CCol lg="12" md="12">
                <CButton
                  v-if="isAllowedUpdatingBankAccount"
                  color="primary"
                  class="btn btn-bank-account"
                  @click="save(bankAccount)"
                >{{$t("BANK_ACCOUNT_COMPONENT_BUTTON_SAVE")}}</CButton>
              </CCol>
            </CRow>
          </ValidationObserver>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'BankAccountComponent',
  data() {
    return {
      bankAccount: {
        bank_name: '',
        account_number: '',
        account_name: '',
        currency_symbol: '',
        branch_name: '',
        account_type: '',
      },
      isAllowedUpdatingBankAccount: false,
    };
  },
  computed: {
    ...mapGetters(['membershipTypes', 'userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();

    this.bankAccount = await this.getBankAccount();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(items => {
          if (items.name === 'MEMBERSHIP_UPDATE_BANK_ACCOUNT_REWARD') {
            this.isAllowedUpdatingBankAccount = true;
          }
        });
      }
    },
    async getBankAccount() {
      try {
        const res = await this.$http.get(endpoints.getBankAccount);
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('BANK_ACCOUNT_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async save(data) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      try {
        const params = {
          bank_name: data.bank_name,
          account_name: data.account_name,
          account_number: data.account_number,
          currency_symbol: data.currency_symbol,
          branch_name: data.branch_name,
          account_type: data.account_type,
          memo: data.memo
        };
        const res = await this.$http.put(endpoints.updateBankAccount, params);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('BANK_ACCOUNT_COMPONENT_TITLE_NOTIFICATION_SUCCESS'),
          text: this.$t('BANK_ACCOUNT_COMPONENT_TITLE_NOTIFY_SUCCESS'),
        });
        this.$emit('save');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('BANK_ACCOUNT_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.bank-account-container {
  .label {
    margin-bottom: 20px;
    label {
      color: #657187;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 8px;
    }
  }
  .wrap-bank-account {
    padding-right: 60px;
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
    .form-group {
      margin-bottom: 0;
      label {
        font-size: 14px;
        font-weight: 300;
        line-height: 34px;
        color: #3e4b65;
        text-align: right;
        padding: 0 5px 0 0;
        @media only screen and (max-width: 767px) {
          text-align: left;
          padding-left: 5px;
          margin-bottom: 5px;
        }
      }
      input {
        font-size: 14px;
        font-weight: 500;
        color: #3a4a7f;
      }
    }
    .group-err-msg {
      margin-bottom: 25px;
    }
  }

  .form-actions {
    margin: 40px 0 30px;
    text-align: right;
    > div {
      padding: 0;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        background-color: #3b4b7e;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }

  .error-msg {
    color: #ff0000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
}
</style>
